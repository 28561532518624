
import { defineComponent } from '@nuxtjs/composition-api'
import { truncate } from '~/assets/util'

export default defineComponent({
  props: {
    entryType: { type: String, required: false, default: undefined },
    entryTitle: { type: String, required: true },
    subtitle: { type: String, required: false, default: undefined },
    entryUrl: { type: String, required: true },
    image: { type: Object, required: false, default: undefined },
    imgAlt: { type: String, required: false, default: undefined },
    collageLarge: { type: Boolean, required: false, default: false },
    collageMedium: { type: Boolean, required: false, default: false },
  },
  setup() {
    return { truncate }
  },
})
