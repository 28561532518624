
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    embed: { type: Object, required: true },
    image: { type: Object, required: false, default: undefined },
    caption: { type: String, required: false, default: null },
    title: { type: String, required: false, default: undefined },
    subtitle: { type: String, required: false, default: undefined },
    description: { type: String, required: true },
    items: { type: Array, required: false, default: () => [] },
  },
})
