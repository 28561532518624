
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    entries: { type: Array, required: true },
    label: { type: String, required: false, default: null },
    subtitle: { type: String, required: false, default: null },
  },
})
