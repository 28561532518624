
import { defineComponent } from '@nuxtjs/composition-api'
import get from 'lodash/get'

export default defineComponent({
  props: {
    page: { type: Object, required: true },
  },
  methods: { get },
})
