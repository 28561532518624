
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    title: { type: String, required: true },
    introduction: { type: String, required: false, default: undefined },
    image: { type: Object, required: false, default: undefined },
  },
})
